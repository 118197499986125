/** @format */

import React, { useEffect, useState } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Banner from "../../components/landingWebsiteComponents/Banner";
import Footer from "../../components/landingWebsiteComponents/Footer";
import Accordion from "react-bootstrap/Accordion";
import SearchedByMap from "../../components/map/Map/SearchedByMap";
import MapComponent from "../../components/MapComponent";
import axios from "axios";
import { SERVERADDRESS } from "../../constants";
import {
  notifyFailure,
  notifySuccess,
  rideNotification,
} from "../../components/toast";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PrivacyPolicyModal from "./PrivacyPolicyModal";

const BookingOnlinePage = () => {
  const directionsService = new window.google.maps.DirectionsService();
  const [activeKey, setActiveKey] = useState("0");
  const [stopArrUseApi, setStopArrUseApi] = useState(false);

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    type: "",
    bookingDate: "",
    seats: "1",
    // willCall: false,
    // needAttended: false,
    // numberOfWheelchairs: "1",
    // amSeats: "0",
    // needWheel: false,
    // needAssigned: false,
    pickUpAddress: "",
    pickUpDate: "",
    pickUpTime: "",
    dropOffAddress: "",
    dropOffDate: "",
    dropOffTime: "",
    dateForReservation: "",
    // driverNotes: "",
    // dispatchNotes: "",
    distanceFromBaseLocation: "",
    distanceFromPickToDropOff: "",
    totalMileage: "",
    isExist: false,
    // loadNumber: '',
    // clientIdExternal: '',
    fareAndDistance: "",
    rideHistory: [],
    serviceName: "",
    driverNotes: "",
    isOnlineBooking: true,
    messageConsent: false,
    returnTrip: "false",
    returnTime: "",
  });
  // console.log(values, "valllllll");

  const [isGuest, setIsGuest] = useState(true);
  const [specialNeed, setSpecialNeed] = useState();
  const handleToggle = () => {
    setSpecialNeed((prevState) => !prevState);
  };
  const handleOptionChange = (event) => {
    const value = event.target.value === "true";
    setSpecialNeed(value);
  };
  // console.log(specialNeed, "event.target.value");

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [stopArr, setStopArr] = useState([
    {
      address: "",
    },
    {
      address: "",
    },
  ]);
  //   console.log(stopArr);

  //   const distanceCalculate = async () => {
  //     await directionsService
  //       .route({
  //         origin: {
  //           query: "3200 North Elston Avenue Chicago, IL 60618",
  //         },
  //         destination: {
  //           query: "1600 Cottage Grove Avenue, Ford Heights, IL, USA",
  //         },
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       })
  //       .then((response) => {
  //         console.log("response", response);
  //         // distanceFromBaseLocation = parseFloat(
  //         //   response.routes[0].legs[0].distance.text
  //         //     .replace(/,/g, "")
  //         //     .split(" mi")[0]
  //         // );
  //         // obj.distanceFromBaseLocation = distanceFromBaseLocation;
  //       })
  //       .catch((e) => {
  //         // notifyFailure(e.message);
  //         console.log("Directions request failed due to ", e.message);
  //         // window.alert("Directions request failed due to ", e)
  //       });
  //   };
  //   useEffect(() => {
  //     distanceCalculate();
  //   }, []);

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true); // User passed reCAPTCHA
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isVerified) {
      for (let key of Object.keys(values)) {
        if (values[key] === "") {
          delete values[key];
        }
      }
      values.dateForReservation =
        values?.pickUpDate?.substring(0, 10) + "T00:00:00.000Z";
      values.bookingDate =
        values?.pickUpDate?.substring(0, 10) + "T00:00:00.000Z";
      values.pickUpDate =
        values?.pickUpDate?.substring(0, 10) +
        "T" +
        values.pickUpTime +
        ":00.000Z";
      values.rideHistory = stopArr;
      //   values.dateForReservation =
      //     stopArr[0].date.substring(0, 10) + "T00:00:00.000Z";
      values.pickUpAddress = stopArr[0].address;
      //   values.pickUpDate = stopArr[0].date;
      //   values.pickUpTime = stopArr[0].time;
      values.dropOffAddress = stopArr[stopArr.length - 1].address;
      //   values.dropOffDate = stopArr[stopArr.length - 1].date;
      //   values.dropOffTime = stopArr[stopArr.length - 1].time;
      if (!specialNeed) {
        delete values.driverNotes;
      }
      for (let key of Object.keys(values)) {
        if (values[key] === "") {
          delete values[key];
        }
      }
      if (values.type === "") {
        values.type = "Ambulatory";
      }
      try {
        const { data } = await axios.post(
          `${SERVERADDRESS}/v2/reservation/addReservation`,
          // { ...values, isExist: !isGuest ? true : false }
          { ...values }
        );
        if (values.returnTrip === "true") {
          const returnTripValues = {
            ...values,
            pickUpAddress: values.dropOffAddress,
            dropOffAddress: values.pickUpAddress,
            pickUpTime: values.returnTime,
          };
          const { data } = await axios.post(
            `${SERVERADDRESS}/v2/reservation/addReservation`,
            { ...returnTripValues }
          );
        }
        notifySuccess(data);
        // setValues({
        //   name: "",
        //   firstName: "",
        //   lastName: "",
        //   name: "",
        //   phone: "",
        //   email: "",
        //   type: "",
        //   isExist: true,
        //   bookingDate: "",
        //   seats: "1",
        //   pickUpAddress: "",
        //   pickUpDate: "",
        //   pickUpTime: "",
        //   dropOffAddress: "",
        //   dropOffDate: "",
        //   dropOffTime: "",
        //   dateForReservation: "",
        //   distanceFromBaseLocation: "",
        //   distanceFromPickToDropOff: "",
        //   totalMileage: "",
        //   fareAndDistance: "",
        //   rideHistory: [],
        //   serviceName: "",
        //   driverNotes: "",
        //   isOnlineBooking: true,
        //   messageConsent: false,
        // });
        // setStopArr([
        //   {
        //     address: "",
        //   },
        //   {
        //     address: "",
        //   },
        // ]);
        setSpecialNeed(false);
      } catch (err) {
        console.log(err, "err");
        notifyFailure(err.response.data.message);
      }
    } else {
      alert("Please complete the reCAPTCHA.");
    }
  };
  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  //   const calculateFareFrontEnd = async () => {
  //     let newArr = [];
  //     stopArr.forEach((e) => {
  //       newArr.push(e?.address?.address);
  //     });
  //     let obj = {};
  //     // yahan se new logic
  //     let eta = { hour: [], mins: [] };
  //     // yahan takh new logic
  //     await directionsService
  //       .route({
  //         origin: {
  //           query: location.baseLocation.address,
  //         },
  //         destination: {
  //           query: newArr[0],
  //         },
  //         travelMode: window.google.maps.TravelMode.DRIVING,
  //       })
  //       .then((response) => {
  //         console.log("response", response);
  //         distanceFromBaseLocation = parseFloat(
  //           response.routes[0].legs[0].distance.text
  //             .replace(/,/g, "")
  //             .split(" mi")[0]
  //         );
  //         obj.distanceFromBaseLocation = distanceFromBaseLocation;
  //       })
  //       .catch((e) => {
  //         notifyFailure(e.message);
  //         console.log("Directions request failed due to ", e.message);
  //         // window.alert("Directions request failed due to ", e)
  //       });
  //     // yahan se new logic
  //     let oldDate = new Date(
  //       stopArr[0].date.substring(0, 10) + "T" + stopArr[0].time + ":00.000Z"
  //     );
  //     // yahan takh new logic
  //     for (let i = 0; i < newArr.length - 1; i++) {
  //       await directionsService
  //         .route({
  //           origin: {
  //             query: newArr[i],
  //           },
  //           destination: {
  //             query: newArr[i + 1],
  //           },
  //           travelMode: window.google.maps.TravelMode.DRIVING,
  //         })
  //         .then((response) => {
  //           console.log("response2", response);
  //           // yahan se new logic
  //           distanceFromPickToDropOff += parseFloat(
  //             response.routes[0].legs[0].distance.text
  //               .replace(/,/g, "")
  //               .split(" mi")[0]
  //           );
  //           // yahan takh new logic
  //           // yahan se new logic
  //           if (response.routes[0].legs[0].duration.text.length > 7) {
  //             const timeString = response.routes[0].legs[0].duration.text;
  //             const hourRegex = /(\d+)\s*hour/;
  //             const minuteRegex = /(\d+)\s*min/;

  //             const hourMatch = hourRegex.exec(timeString);
  //             const minuteMatch = minuteRegex.exec(timeString);

  //             const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
  //             const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
  //             eta.hour.push(hours);
  //             eta.mins.push(minutes);

  //             let aaa = (hours * 60 + minutes) * 60 * 1000;
  //             console.log(aaa, oldDate, "eta aarha hai");
  //             oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
  //             let oldDateTime = oldDate.toString().split(" ")[4];
  //             let ind = i + 1;
  //             setStopArr(
  //               stopArr.map((stop, index) =>
  //                 index === ind
  //                   ? {
  //                       ...stop,
  //                       date: new Date(oldDate).toISOString().substring(0, 10),
  //                       time: oldDateTime.substring(0, 5),
  //                     }
  //                   : { ...stop }
  //               )
  //             );
  //             console.log(
  //               aaa,
  //               new Date(oldDate).toISOString(),
  //               oldDateTime.substring(0, 5),
  //               "eta aarha hai"
  //             );
  //           } else {
  //             eta.mins.push(
  //               parseInt(
  //                 response.routes[0].legs[0].duration.text.split(" mins")[0]
  //               )
  //             );
  //             // let oldDate = new Date(stopArr[0].date.substring(0, 10) + "T"+stopArr[0].time+":00.000Z").getTime();
  //             let aaa =
  //               parseInt(
  //                 response.routes[0].legs[0].duration.text.split(" mins")[0]
  //               ) *
  //               60 *
  //               1000;
  //             oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
  //             let oldDateTime = oldDate.toString().split(" ")[4];
  //             let ind = i + 1;
  //             setStopArr(
  //               stopArr.map((stop, index) =>
  //                 index === ind
  //                   ? {
  //                       ...stop,
  //                       date: new Date(oldDate).toISOString().substring(0, 10),
  //                       time: oldDateTime.substring(0, 5),
  //                     }
  //                   : { ...stop }
  //               )
  //             );
  //             console.log(
  //               aaa,
  //               oldDate,
  //               oldDateTime.substring(0, 5),
  //               "eta aarha hai"
  //             );
  //           }
  //           console.log("etaeta,", eta);
  //           // yahan takh new logic
  //         })
  //         .catch((e) => {
  //           notifyFailure(e.message);
  //           console.log("Directions request failed due to ", e);
  //           // window.alert("Directions request failed due to ", e)
  //         });
  //     }
  //     // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
  //     distanceFromBaseLocation = parseFloat(distanceFromBaseLocation.toFixed(2));
  //     obj.apiDistanceFromBaseLocation = distanceFromBaseLocation;
  //     distanceFromPickToDropOff = parseFloat(
  //       distanceFromPickToDropOff.toFixed(2)
  //     );
  //     obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
  //       obj.usePrecalculatedMiles = false;
  //     totalMileage = parseFloat(
  //       (distanceFromBaseLocation + distanceFromPickToDropOff).toFixed(2)
  //     );
  //     obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
  //     obj.totalMileage = totalMileage;
  //   };

  return (
    <>
      <NavBar />
      <Banner name="Booking Trip" />
      <div class="abt-section">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12 mb-3">
              <div class="bookingtext">
                <p>
                  Ready to book your trip? Simply enter your details below, and
                  one of our team members will reach out to you shortly to
                  confirm your booking.
                </p>
              </div>
            </div>
            <div class="col-lg-10 col-md-12">
              <form
                class="row"
                onSubmit={handleSubmit}
                style={{ textAlign: "start" }}
              >
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="">
                      First Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control bg-gray"
                      required
                      value={values.firstName}
                      onChange={handleChange("firstName")}
                      placeholder="First Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="">
                      Last Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      class="form-control bg-gray"
                      required
                      value={values.lastName}
                      onChange={handleChange("lastName")}
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="">
                      Phone Number <span class="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      class="form-control bg-gray"
                      value={values.phone}
                      // onChange={handleChange('phone')}
                      onChange={handlePhone("phone")}
                      placeholder="(123)456-7890"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="">
                      Email Address <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      class="form-control bg-gray"
                      required
                      value={values.email}
                      onChange={handleChange("email")}
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="">
                      Pick-Up Date<span class="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      class="form-control bg-gray height-35"
                      required
                      value={values.pickUpDate}
                      onChange={handleChange("pickUpDate")}
                    />
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <div class="form-group">
                    <label class="form-label" for="">
                      Pick-Up Time <span class="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      class="form-control bg-gray height-35"
                      required
                      value={values.pickUpTime}
                      onChange={handleChange("pickUpTime")}
                    />
                  </div>
                </div>
                {/* <div class="row"> */}
                <div class="col-md-6 mb-3">
                  {stopArr.length > 0 &&
                    stopArr.map((current, i) => {
                      return (
                        <>
                          <div class="form-group">
                            <label class="form-label" for="">
                              {i === 0 ? "Pickup Address" : "Drop-off Address"}{" "}
                              <span class="text-danger">*</span>
                            </label>
                            <SearchedByMap
                              // class="form-control bg-gray"
                              stopArr={stopArr}
                              setStopArr={setStopArr}
                              field="address"
                              index={i}
                              // setStopArrUseApi={setStopArrUseApi}
                              searchAddress={stopArr[i].address.address}
                            />
                          </div>
                        </>
                      );
                    })}
                  <div>
                    <div>
                      <label class="form-label" for="">
                        Type of Service
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value={"Wheelchair"}
                        checked={values.type === "Wheelchair"}
                        onChange={handleChange("type")}
                      />
                      <label class="form-check-label" for="inlineCheckbox1">
                        Wheelchair
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value={"Ambulatory"}
                        checked={values.type === "Ambulatory"}
                        onChange={handleChange("type")}
                      />
                      <label class="form-check-label" for="inlineCheckbox2">
                        Ambulatory
                      </label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label class="form-label" for="">
                        Type of Trip
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value={"false"}
                        checked={values.returnTrip === "false"}
                        onChange={handleChange("returnTrip")}
                      />
                      <label class="form-check-label" for="inlineCheckbox1">
                        One-way
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value={"true"}
                        checked={values.returnTrip === "true"}
                        onChange={handleChange("returnTrip")}
                      />
                      <label class="form-check-label" for="inlineCheckbox2">
                        Return Trip
                      </label>
                    </div>
                  </div>
                  {values.returnTrip === "true" && (
                    <div>
                      <div>
                        <label class="form-label">
                          Return Time <span class="text-danger">*</span>
                        </label>
                      </div>
                      {/* <div class="form-check form-check-inline"> */}
                      <input
                        type="time"
                        class="form-control bg-gray height-35"
                        required
                        value={values.returnTime}
                        onChange={handleChange("returnTime")}
                      />
                      {/* </div> */}
                    </div>
                  )}
                  {/* <div>
                    <div>
                      <label class="form-label" for="">
                        Do you require any special needs?
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value={true}
                        checked={specialNeed === true}
                        onChange={handleOptionChange}
                      />
                      <label class="form-check-label" for="inlineCheckbox1">
                        Yes
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input bg-gray"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value={false}
                        checked={specialNeed === false}
                        onChange={handleOptionChange}
                      />
                      <label class="form-check-label" for="inlineCheckbox2">
                        No
                      </label>
                    </div>
                  </div> */}
                  {
                    // specialNeed &&
                    <>
                      <label
                        className="bokinglabel"
                        for="flexSwitchCheckDefault"
                      >
                        Anything else you'd like to share?
                      </label>
                      <textarea
                        rows="2"
                        placeholder="Share your special needs..."
                        cols="50"
                        className="form-control f-14 bg-gray"
                        value={values.driverNotes}
                        onChange={handleChange("driverNotes")}
                      >
                        {" "}
                      </textarea>
                    </>
                  }
                </div>
                <div className="col-md-6 map-container">
                  <MapComponent stopArr={stopArr} />
                  <br />
                </div>
                <div class="col-md-8 mb-3">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input bg-gray"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                      checked={values.messageConsent}
                      onChange={() =>
                        setValues((prevValues) => ({
                          ...prevValues,
                          messageConsent: !prevValues.messageConsent,
                        }))
                      }
                    />
                    <label class="form-check-label" for="inlineCheckbox1">
                      By checking this box, I agree to receive SMS messages
                      about notification from{" "}
                      <strong style={{ color: "#001C3D" }}>
                        Connect Care Trans,
                      </strong>{" "}
                      at the phone number provided above. The SMS frequency may
                      vary. Data rates may apply. Text HELP to 773-340-4373 for
                      assistance. Reply STOP to opt out of receiving SMS
                      messages.{" "}
                      <a
                        href="https://www.connectcaretrans.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#1d82f5" }}
                      >
                        {" "}
                        (Privacy Policy/Terms of Use)
                      </a>
                      {/* <button
                        type="button"
                        // href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#PrivacypolicyModal"
                        class="text-decoration-none"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <strong
                          onClick={() => {
                            openDropDown();
                          }}
                        >
                          (Privacy Policy/Terms of Use)
                        </strong>
                      </button> */}
                    </label>
                  </div>
                </div>
                {/* </div> */}
                <div class="col-md-12">
                  <ReCAPTCHA
                    sitekey="6LeYbncqAAAAAA8-sdp7cj5bwH8QmMw-N8q14dsv"
                    onChange={handleRecaptchaChange}
                  />
                  <br />
                </div>
                <div class="col-md-12">
                  <button
                    class="btn btn-secondary btn-lg rounded-2 px-5 fw-bold"
                    style={{ color: "#001C3D" }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="row justify-content-center ">
            <div
              className="col-md-10 col-sm-12"
              style={{ fontFamily: "inter, sans-serif" }}
            >
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body row">
                  <div className="col-md-6 mb-3">
                    <form className="row" onSubmit={handleSubmit}>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="fName" className="bokinglabel mb-2">
                          First Name
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          className="form-control rounded-0"
                          type="text"
                          name="fName"
                          id="fName"
                          required
                          value={values.firstName}
                          onChange={handleChange("firstName")}
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="lName" className="bokinglabel mb-2">
                          Last Name
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          className="form-control rounded-0"
                          type="text"
                          name="lName"
                          id="lName"
                          required
                          value={values.lastName}
                          onChange={handleChange("lastName")}
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="phoneNumber"
                          className="bokinglabel mb-2"
                        >
                          Phone Number
                        </label>
                        <input
                          className="form-control rounded-0"
                          type="tel"
                          name="phoneNumber"
                          id="phoneNumber"
                          // required
                          value={values.phone}
                          // onChange={handleChange('phone')}
                          onChange={handlePhone("phone")}
                          placeholder="(123)456-7890"
                        />
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="emailAddress"
                          className="bokinglabel mb-2"
                        >
                          Email Address
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          className="form-control rounded-0"
                          type="email"
                          name="emailAddress"
                          id="emailAddress"
                          required
                          value={values.email}
                          onChange={handleChange("email")}
                          placeholder="Email"
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label for="pickupDate" className="bokinglabel mb-2">
                          Pick-Up Date
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          className="form-control rounded-0 "
                          type="date"
                          name="pickupDate"
                          id="pickupDate"
                          required
                          value={values.pickUpDate}
                          onChange={handleChange("pickUpDate")}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label for="pickupTime " className="bokinglabel mb-2">
                          Pick-Up Time
                        </label>
                        <sup className="text-danger f-12">*</sup>
                        <input
                          className="form-control rounded-0 "
                          type="time"
                          name="pickupTime"
                          id="pickupTime"
                          required
                          value={values.pickUpTime}
                          onChange={handleChange("pickUpTime")}
                        />
                      </div>
                      {stopArr.length > 0 &&
                        stopArr.map((current, i) => {
                          return (
                            <>
                              <div className="col-md-12 mb-3" key={i}>
                                <label
                                  for="pickupLocation "
                                  className="bokinglabel mb-2"
                                >
                                  {i === 0
                                    ? "Pickup Address"
                                    : "Drop-off Address"}
                                </label>
                                <sup className="text-danger f-12">*</sup>
                                <SearchedByMap
                                  stopArr={stopArr}
                                  setStopArr={setStopArr}
                                  field="address"
                                  index={i}
                                  searchAddress={stopArr[i].address.address}
                                />
                              </div>
                            </>
                          );
                        })}
                      <div className="col-md-12 mb-3">
                        <div>
                          <label
                            className="bokinglabel"
                            for="flexSwitchCheckDefault"
                          >
                            Do you require any special needs?
                            <label>
                              &nbsp;
                              <input
                                type="radio"
                                value={true}
                                checked={specialNeed === true}
                                onChange={handleOptionChange}
                              />
                              Yes
                            </label>
                            &nbsp;or&nbsp;
                            <label>
                              <input
                                type="radio"
                                value={false}
                                checked={specialNeed === false}
                                onChange={handleOptionChange}
                              />
                              No
                            </label>
                          </label>
                        </div>
                        {specialNeed && (
                          <>
                            <label
                              className="bokinglabel"
                              for="flexSwitchCheckDefault"
                            >
                              Anything else you'd like to share?
                            </label>
                            <textarea
                              rows="2"
                              cols="50"
                              className="form-control f-14"
                              value={values.driverNotes}
                              onChange={handleChange("driverNotes")}
                            >
                              {" "}
                            </textarea>
                          </>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <div>
                          <label
                            className="bokinglabel"
                            for="flexSwitchCheckDefault"
                          >
                            <input
                              type="checkbox"
                              checked={values.messageConsent}
                              onChange={() =>
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  messageConsent: !prevValues.messageConsent,
                                }))
                              }
                            />
                            &nbsp;By Checking this box you agree to receive text
                            messages from Connect Care Trans, you can reply to
                            opt-out at any time, this is my
                            <Link to="/privacy-policy"> privacy policy</Link>.
                          </label>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                          <ReCAPTCHA
                            sitekey="6LeYbncqAAAAAA8-sdp7cj5bwH8QmMw-N8q14dsv"
                            onChange={handleRecaptchaChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="register">
                          <button
                            className="btn btn-outline-primary w-100 rounded-0 "
                            type="submit"
                            style={{ margin: "5px" }}
                          >
                            {" "}
                            Submit{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6">
                    <MapComponent stopArr={stopArr} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <PrivacyPolicyModal
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
      <Footer />
    </>
  );
};
export default BookingOnlinePage;
