import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../components/landingWebsiteComponents/NavBar";
import Footer from "../../components/landingWebsiteComponents/Footer";
import SignPdf from "./SignPdf";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import generatePDF from "react-to-pdf";
import {
  SERVERADDRESS,
  SQUARE_UP_APPLICATION_ID,
  SQUARE_UP_OAUTH_ACCESS_TOKEN,
} from "../../constants";
import axios from "axios";
import SignPdf2 from "./SignPdf2";
import { useLocation, useNavigate } from "react-router-dom";
import { notifyFailure } from "../../components/toast";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import linkExpire from "../../assets/images/expirelink.png";
import transaction from "../../assets/images/transaction.png";
import creditCard from "../../assets/images/creditcard.png";

// import './TermsAndConditions.css'; // Extract styles into a separate CSS file for cleaner React code.

const SignTermsAndConditions = () => {
  const targetRef = useRef();
  const fullUrl = window.location.href;
  const [loading, setLoading] = useState(true);
  const [expire, setExpire] = useState(false);
  const [checkOutView, setCheckoutView] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const locationUrl = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(locationUrl.search);
  const name = queryParams.get("name");
  const email = queryParams.get("email");
  const refId = queryParams.get("refId");
  const oId = queryParams.get("oId");
  useEffect(() => {
    if (fullUrl) {
      urlValidator();
    }
  }, [fullUrl]);
  useEffect(() => {
    if (name) {
      const nameParts = name.split(":");
      setValues({
        ...values,
        "Passenger Name": nameParts[0] + " " + nameParts[1],
      });
    }
  }, [name]);
  const urlValidator = async () => {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/url/checkUrl`, {
      link: fullUrl,
    });
    // console.log(data, "data");
    if (data !== "not found") {
      // console.log("chala2");
      setLoading(false);
      const expiryDate = new Date(data.expires);
      const currentDate = new Date();
      // if (moment().isAfter(data.expires)) {
      if (currentDate > expiryDate) {
        // navigate("/");
        setExpire(true);
      } else if (data.isUsed === true) {
        setCheckoutView(true);
      }
      setCustomer({
        ...data.userId,
      });
    } else {
      // console.log("chala");
      setLoading(false);
      setExpire(true);
      // navigate("/");
    }
  };
  const [currentStep, setCurrentStep] = useState(0);
  const [start, setStart] = useState(false);
  const fields = [
    "Passenger Name",
    // "By",
    "Signature",
  ];
  const [values, setValues] = useState({
    "Passenger Name": "",
    // By: "",
    Signature: "",
    // Its: "",
  });
  const [customer, setCustomer] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  // console.log(customer);
  const handleInputChange = (field, value) => {
    if (start === false) {
      setStart(true);
    }
    setValues({ ...values, [field]: value });
    const newStep = fields.indexOf(field);
    // console.log(newStep, "newStep");
    if (newStep !== -1 && newStep !== currentStep) {
      setCurrentStep(newStep);
    }
  };

  const nextStep = async () => {
    if (start === false) {
      setStart(true);
    }
    const allValuesFilled = Object.values(values).every(
      (value) => value !== ""
    );
    // console.log(values[fields[currentStep]], "sssssss");
    if (!values[fields[currentStep]] || values[fields[currentStep]] === "") {
      // console.log("object2");
      const currentField = document.querySelector(
        `.input-field-${currentStep + 1}`
      );
      if (currentField)
        currentField.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      // console.log("object3");
      if (currentStep < fields.length - 1) {
        // console.log("object");
        setCurrentStep(currentStep + 1);
        // Scroll to the next input field
        const nextField = document.querySelector(
          `.input-field-${currentStep + 1}`
        );
        if (nextField)
          nextField.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    if (allValuesFilled) {
      if (values["Passenger Name"] === values.Signature) {
        await handleGenerateAndUpload();
      } else {
        notifyFailure("Passenger Name and Signature are not same");
      }
    }
  };

  const proceed = async () => {
    if (values["Passenger Name"] === values.Signature) {
      await handleGenerateAndUpload();
    } else {
      notifyFailure("Passenger Name and Signature are not same");
    }
  };

  const handleUpload = async (pdfData) => {
    const formData = new FormData();
    formData.append("file", new Blob([pdfData], { type: "application/pdf" }));

    try {
      const response = await axios.put(
        `${SERVERADDRESS}/v1/fileupload/uploadDocumentClient`,
        formData
      );

      // const result = await response.json();
      console.log("Upload successful:", response);
    } catch (error) {
      console.error("Error uploading the PDF:", error);
    }
  };
  // const uploadFileToApi = async (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   const response = await axios.put(
  //     `${SERVERADDRESS}/v1/fileupload/uploadDocument`,
  //     formData
  //   );

  //   // if (!response.ok) {
  //   //   throw new Error("File upload failed");
  //   // }

  //   return response.json();
  // };
  const handleGenerateAndUpload = async () => {
    try {
      // Generate the PDF as a file
      const asPdf = pdf(<SignPdf fields={fields} values={values} />);
      const file = await asPdf.toBlob();

      // Upload the file directly to the server
      const formData = new FormData();
      formData.append(
        "file",
        file,
        `${values["Passenger Name"]} Terms and Conditions.pdf`
      );
      formData.append("email", email);
      // const response = await axios.post('http://your-server-url/upload', formData, {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // });

      const response = await axios.put(
        `${SERVERADDRESS}/v1/fileupload/uploadDocumentClient`,
        formData
      );
      await urlValidator();
      // console.log("File uploaded successfully:", response);
    } catch (error) {
      console.error("Error generating or uploading file:", error);
    }
  };

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues({
      ...values,
      [fieldName]: formattedValue,
    });
  };

  return (
    <>
      <NavBar />
      {!loading && !checkOutView && expire && (
        <>
          <div className="container">
            <div className="row contact-area justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
                <div className="pricing-6-item">
                  <div className="section-title-6 text-center">
                    <img
                      src={linkExpire}
                      alt="expirylink"
                      style={{ width: "40%" }}
                    />
                    {/* <h3 className="title">
                      Link has either expired or removed.
                    </h3> */}
                    <h5 className="">
                      This link is no longer active. It may have expired or been
                      deactivated. If you need assistance, please contact us at
                      (773) 340-4373.
                    </h5>
                    {/* <p></p> */}
                  </div>
                  <div className="">
                    {/* <div><strong>Who we are</strong></div> */}
                    {/* <div>
                  <strong>Link has either expired or removed.</strong>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
      {!loading && !checkOutView && !expire && (
        <>
          <div className="container" ref={targetRef}>
            <div class="row justify-content-center mb-3">
              <div class="col-lg-6 col-md-8">
                <div class="row justify-content-center">
                  <ul
                    class="nav nav-tabs gap-3 justify-content-center"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        // href="terms-and-conditions.html"
                        class="nav-link active"
                        type="button"
                      >
                        Terms & Conditions
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" type="button">
                        Payment
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-md-4">
                <label for="" class="form-label">
                  PASSENGER NAME:
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={values["Passenger Name"]}
                  name=""
                  id=""
                  readonly
                  disabled
                />
              </div>
              <div class="col-md-4">
                <label for="" class="form-label">
                  REFERENCE NO.
                </label>
                <input
                  type="text"
                  class="form-control"
                  value={oId}
                  id=""
                  readonly
                  disabled
                />
              </div>
              <div class="col-md-12  my-3">
                <div class="abtwel-text">
                  <h3>Terms and Conditions – Medical Transportation</h3>
                  <p>(Connect Care Trans LLC)</p>
                </div>
              </div>

              <div class="col-md-12 ">
                <p>
                  The following constitute the terms and conditions (the “Terms
                  and Conditions”) to which you (‘You”, “Your”, or “Passenger”)
                  agree to be bound when booking any non-emergency
                  transportation services (the “Services”) with Connect Care
                  Trans LLC (“we”, “our”, “us” or “Connect Care Trans”). If you
                  do not have such authority, or if you do not agree with these
                  Terms and Conditions, you must not accept these Terms and
                  Conditions and may not use our Services.{" "}
                </p>
                <p>
                  By accepting these Terms and Conditions, either by clicking a
                  box indicating your acceptance, or by executing a copy of
                  these Terms and Conditions, or by booking and utilizing the
                  Service via phone with a copy of these Terms and Conditions
                  provided to you, you agree to be bound by this Terms and
                  Conditions.{" "}
                </p>
                <p>
                  <strong>Booking</strong>. All Services must be booked online
                  or via phone. Bookings must include accurate pickup and
                  drop-off locations, as well as any other information and
                  requirements specified by us. You will receive a confirmation
                  email upon successfully booking a trip.
                </p>
                <p>
                  <strong>Services</strong>. The Services are only provided at
                  the time specified by you and do not include any emergency
                  medical transportation services. The determination of whether
                  a transport is an “emergency” or “non-emergency” shall be made
                  by us in our sole discretion. We reserve the right to refuse
                  to provide the Services to any passenger who fails to comply
                  with these Terms and Conditions, exhibits inappropriate
                  behavior, or we determine as unfit for the Services in our
                  sole discretion.{" "}
                </p>
                <p>
                  <strong>Fees and Payment.</strong> We shall charge the fees as
                  set forth on Exhibit A for the Services provided hereunder
                  (the “Fees”). We shall have the right to change the Fees upon
                  prior notice to you. We accept the payment of the Fees at the
                  time of booking through credit cards and other online payment
                  methods listed on our website. Payment must be completed in
                  full to confirm a trip.{" "}
                </p>
                <p>
                  <strong>Refund Policy.</strong> Refunds will only be issued if
                  the trip is canceled by us or if a cancellation is made by you
                  in accordance with our cancellation policy specified in these
                  Terms and Conditions. No refund will be issued for no-shows.
                </p>
                <p>
                  <strong>Cancellation</strong>. Cancellations must be made at
                  least 24 hours prior notice to the scheduled trip, in order
                  for you to receive a refund for your trip. If a cancellation
                  is made within 24 hours of the scheduled trip, we will charge
                  a cancellation fee of $60 dollars.{" "}
                </p>
                <p>
                  <strong>No Show.</strong> If the passenger fails to appear at
                  the designated pickup location within 10 minutes of the
                  scheduled pick-up time, it will be considered a “no-show”. No
                  refund will be issued for no-shows, and additional charges may
                  apply if rescheduling is requested
                </p>
                <p>
                  <strong>Special Accommodations</strong>. We are happy to
                  accommodate you with your special needs or disability to the
                  extent of our available resources and capabilities. However,
                  you are responsible for notifying us of any specific
                  accommodation required at the time of scheduling the trip. The
                  driver will make reasonable efforts to provide the requested
                  accommodations, however, providing such accommodations is not
                  guaranteed.{" "}
                </p>
                <p>
                  <strong>Equipment.</strong> You may request the use of
                  additional equipment for your trip (wheelchair, child car
                  seat, booster seat, oxygen tank, etc.) for an additional fee.
                  Such request must be made in advance at the time of booking.
                  The availability of any equipment is not guaranteed for every
                  trip. All equipment provided remains the property of us and
                  must be returned in the same condition at the end of your
                  trip. You will be responsible for any damage, loss, or theft
                  of the equipment during the trip and will be charged a fee to
                  cover the cost of repair or replacement
                </p>
                <h4>Passenger Obligations. </h4>
                <ul className="li-left-space">
                  <li>
                    a. You agree to pay the Fees in full at the time of booking
                    your trip and in compliance with these Terms and Conditions.
                  </li>
                  <li>
                    b. Prior to the scheduled trip, you must disclose any
                    relevant medical conditions, special needs or requirements,
                    including but not limited to, mobility aids, allergies,
                    oxygen equipment or other equipment. Please note that we may
                    not be able to accommodate certain requests without prior
                    notice and approval.{" "}
                  </li>
                  <li>
                    c. You are expected to behave in a respectful and safe
                    manner during the trip. We reserve the right to refuse
                    Services or remove any Passenger whose behavior is
                    disruptive, harmful, or poses a risk to others, without any
                    refund or compensation to you.
                  </li>
                  <li>
                    d. If the Passenger is a minor, a legal guardian must
                    complete and sign the Consent Form prior to the scheduled
                    trip.{" "}
                  </li>
                  <li>
                    e. Smoking, eating, and drinking are prohibited in the
                    vehicle unless there is a disclosed medical condition that
                    requires it.{" "}
                  </li>
                  <li>
                    f. The Passenger must comply with all local, state, and
                    federal laws during the trip
                  </li>
                  <li>
                    g. The Passenger is responsible for ensuring the belongings
                    are secured during the trip. We are not responsible for lost
                    or damaged items.{" "}
                  </li>
                  <li>
                    h. You agree to be bound by the refund and cancellation
                    policies outlined in these Terms and Conditions.
                  </li>
                </ul>
                <h4>Limitation of Liability and Liability Waiver.</h4>
                <p>
                  To the fullest extent permitted by applicable law and
                  regulation, in no event shall Connect Care Trans or any of its
                  members, managers, officers, employees, agents, drivers, or
                  contractors be liable to you or any third party for any
                  direct, indirect, consequential, incidental, or punitive
                  damages relating to the provision of the Services, regardless
                  of whether such damages are foreseeable or whether Connect
                  Care Trans has been advised of the possibility of such
                  damages, except where such damages or injuries are caused by
                  the gross negligence or willful misconduct of Connect Care
                  Trans. Further, Connect Care Trans shall not be responsible
                  for delays or interruptions in Services due to traffic,
                  weather, road conditions, or other circumstances beyond its
                  control. You acknowledge and agree that by using the Services,
                  you, and your guardians, agree to waive, release, and hold
                  harmless Connect Care Trans from any claims demands, or
                  liabilities arising from any injuries, damages, or losses
                  incurred during the trip, to the fullest extent permitted by
                  applicable law, except those resulting from the gross
                  negligence or willful misconduct of Connect Care Trans.
                </p>
                <h4>Indemnification. </h4>
                <p>
                  You shall indemnify, defend, and hold harmless Connect Care
                  Trans and its members, managers, employees, agents, or
                  representatives (“Indemnified Party”) against any and all
                  losses, damages, liabilities, deficiencies, claims, actions,
                  judgments, settlements, interest, penalties, fines, costs, or
                  expenses of whatever kind, including reasonable attorneys’
                  fees, that are incurred by Indemnified Party (collectively,
                  “Losses”), arising out of or related to any claim alleging (1)
                  breach of these Terms and Conditions by you, (2) any bodily
                  injury, death of any person or damage to real and tangible
                  property caused by the negligent action or omission of you,
                  and (3) failure by you to comply with any applicable law or
                  regulation. This indemnification obligation shall survive the
                  termination of Services.{" "}
                </p>
                <h4>General Provisions. </h4>
                <ul className="li-left-space">
                  <li>
                    a. <strong>Entire Agreement.</strong> The Terms and
                    Conditions, including and together with any related
                    exhibits, schedules, and attachments constitute the sole and
                    entire agreement of the parties with respect to the subject
                    matter contained herein, and supersedes all prior and
                    contemporaneous understandings, agreements, representations,
                    and warranties, both written and oral, regarding such
                    subject matter.{" "}
                  </li>
                  <li>
                    b. <strong>Assignment</strong> While Passenger may not
                    assign the right and obligation under these Terms and
                    Conditions to any third party, Connect Care Trans may assign
                    its rights to a third party, provided that such assignment
                    shall not affect the provision of the agreed upon Services
                    and other obligations under these Terms and Conditions.
                  </li>
                  <li>
                    c. <strong>No Waiver</strong> No waiver by any party of any
                    of the provisions of these Terms and Conditions shall be
                    effective unless explicitly set forth in writing and signed
                    by the party so waiving. No failure to exercise any right or
                    remedy arising from these Terms and Conditions shall be
                    construed as a waiver of any other right and remedy.
                  </li>
                  <li>
                    d. <strong>Relationship of the Parties</strong> Nothing
                    contained in these Terms and Conditions shall be construed
                    as creating any agency, partnership, joint venture, or other
                    form of joint enterprise, employment, or fiduciary
                    relationship between the parties, and neither party shall
                    have the authority to contract for or bind the other party
                    in any manner whatsoever.
                  </li>
                  <li>
                    e. <strong>Survival.</strong> The provisions of these Terms
                    and Conditions shall survive the termination of these Terms
                    and Conditions to the extent necessary to effectuate the
                    terms contained herein.{" "}
                  </li>
                  <li>
                    f. <strong>Dispute Resolution. </strong> In the event of any
                    controversy or claim arising out of or relating to the Terms
                    and Conditions, the parties hereto shall consult and
                    negotiate with each other in good faith and attempt to reach
                    a solution satisfactory to both parties. If the parties
                    cannot agree to a resolution within 30 days after the
                    aggrieved party’s written notice to the other party, the
                    terms of this provision shall be deemed fulfilled.
                  </li>
                  <li>
                    g.{" "}
                    <strong>Governing Law; Venue; Waiver of Jury Trial:</strong>{" "}
                    The Terms and Conditions shall be governed by and construed
                    in accordance with the laws of the State of Illinois
                    (without giving effect to its laws of conflicts). The
                    parties agree that any legal action or proceeding with
                    respect to any of their respective obligations under these
                    Terms and Conditions shall be brought exclusively in the
                    County of Cook, State of Illinois. THE PARTIES HERETO HEREBY
                    IRREVOCABLY WAIVE ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY
                    LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THE TERMS AND
                    CONDITIONS.{" "}
                  </li>
                  <li>
                    h. <strong>Severability</strong>. If any provision of these
                    Terms and Conditions is held invalid or unenforceable by any
                    court of competent jurisdiction, the other provisions will
                    remain in full force and effect.{" "}
                  </li>
                </ul>
                <br />
              </div>
            </div>
            <div class="p-2 p-sm-4 bg-white border mb-3 rounded-1  shadow-sm">
              <div class="row justify-content-between">
                <div class="col-lg-3 col-md-12">
                  <h4>Agreed And Accepted:</h4>
                  {fields.map((field, index) => (
                    <div class="mb-3" key={index}>
                      <label class="form-label" for="">
                        {field}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        value={values[field]}
                        onChange={(e) =>
                          handleInputChange(field, e.target.value)
                        }
                        disabled={index === 0}
                      />
                    </div>
                  ))}
                </div>
                {/* <div class="col-lg-8 col-md-12">
                  <h4 class="text-start">Exhibit A</h4>
                  <p class="text-start">Service Schedule </p>
                  <form action="#" class="row mb-3 ">
                    <div class="col-md-12 mb-3">
                      <label for="" class="form-label">
                        Service{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        value="Waheelchair"
                        readonly
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="" class="form-label">
                        Service Time
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        value="6:00 PM"
                        readonly
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="" class="form-label">
                        Fee{" "}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        value="$35"
                        readonly
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label for="" class="form-label">
                        Contact Person(s)
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        value="NA"
                        readonly
                      />
                    </div>
                    <div class="col-md-12">
                      <label for="" class="form-label">
                        Special Need or Accommodation Required
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        value="NA"
                        readonly
                      />
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <div class="col-md-12 text-center">
              <button
                // href="#"
                class="btn btn-secondary px-5 rounded-1"
                type="button"
                onClick={proceed}
              >
                {" "}
                Next{" "}
              </button>
            </div>
            {/* <div className="row contact-area justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
                <div className="pricing-6-item">
                  <div className="section-title-6 text-center">
                    <h3 className="title">
                      Terms and Conditions – Medical Transportation
                    </h3>
                    <h5 className="">(Connect Care Trans LLC)</h5>
                    <p></p>
                  </div>
                  <div className="">
                    <div>
                      The following constitute the terms and conditions (the{" "}
                      <strong>“Terms and Conditions”</strong>) to which you (
                      <strong>“You”</strong>, <strong>“Your”</strong>, or{" "}
                      <strong>“Passenger”</strong>) agree to be bound when
                      booking any non-emergency transportation services (the{" "}
                      <strong>“Services”</strong>) with Connect Care Trans LLC (
                      <strong>“we”</strong>, <strong>“our”</strong>,{" "}
                      <strong>“us”</strong> or{" "}
                      <strong>“Connect Care Trans”</strong>
                      ). If you do not have such authority, or if you do not
                      agree with these Terms and Conditions, you must not accept
                      these Terms and Conditions and may not use our Services.
                    </div>
                    <br />
                    <div>
                      By accepting these Terms and Conditions, either by
                      clicking a box indicating your acceptance, or by executing
                      a copy of these Terms and Conditions, or by booking and
                      utilizing the Service via phone with a copy of these Terms
                      and Conditions provided to you, you agree to be bound by
                      this Terms and Conditions.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Booking.</u>
                      </strong>{" "}
                      All Services must be booked online or via phone. Bookings
                      must include accurate pickup and drop-off locations, as
                      well as any other information and requirements specified
                      by us. You will receive a confirmation email upon
                      successfully booking a trip.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Services.</u>
                      </strong>{" "}
                      The Services are only provided at the time specified by
                      you and do not include any emergency medical
                      transportation services. The determination of whether a
                      transport is an <strong>“emergency”</strong> or{" "}
                      <strong>“non-emergency”</strong> shall be made by us in
                      our sole discretion. We reserve the right to refuse to
                      provide the Services to any passenger who fails to comply
                      with these Terms and Conditions, exhibits inappropriate
                      behavior, or we determine as unfit for the Services in our
                      sole discretion.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Fees and Payment.</u>
                      </strong>{" "}
                      We shall charge the fees as set forth on Exhibit A for the
                      Services provided hereunder (the <strong>“Fees”</strong>).
                      We shall have the right to change the Fees upon prior
                      notice to you. We accept the payment of the Fees at the
                      time of booking through credit cards and other online
                      payment methods listed on our website. Payment must be
                      completed in full to confirm a trip.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Refund Policy.</u>
                      </strong>{" "}
                      Refunds will only be issued if the trip is canceled by us
                      or if a cancellation is made by you in accordance with our
                      cancellation policy specified in these Terms and
                      Conditions. No refund will be issued for no-shows.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Cancellation.</u>
                      </strong>{" "}
                      Cancellations must be made at least 2 hours prior notice
                      to the scheduled trip, in order for you to receive a
                      refund for your trip. If a cancellation is made within 2
                      hours of the scheduled trip, we will charge a cancellation
                      fee of $60 dollars.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u> No Show.</u>
                      </strong>{" "}
                      If the passenger fails to appear at the designated pickup
                      location within 10 minutes of the scheduled pick-up time,
                      it will be considered a <strong>“no-show”</strong>. No
                      refund will be issued for no- shows, and additional
                      charges may apply if rescheduling is requested.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u> Special Accommodations.</u>
                      </strong>{" "}
                      We are happy to accommodate you with your special needs or
                      disability to the extent of our available resources and
                      capabilities. However, you are responsible for notifying
                      us of any specific accommodation required at the time of
                      scheduling the trip. The driver will make reasonable
                      efforts to provide the requested accommodations, however,
                      providing such accommodations is not guaranteed.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u> Equipment.</u>
                      </strong>{" "}
                      You may request the use of additional equipment for your
                      trip (wheelchair, child car seat, booster seat, oxygen
                      tank, etc.) for an additional fee. Such request must be
                      made in advance at the time of booking. The availability
                      of any equipment is not guaranteed for every trip. All
                      equipment provided remains the property of us and must be
                      returned in the same condition at the end of your trip.
                      You will be responsible for any damage, loss, or theft of
                      the equipment during the trip and will be charged a fee to
                      cover the cost of repair or replacement
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Passenger Obligations.</u>
                      </strong>
                    </div>
                    <br />
                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                      <div>
                        a. You agree to pay the Fees in full at the time of
                        booking your trip and in compliance with these Terms and
                        Conditions.
                      </div>
                      <div>
                        b. Prior to the scheduled trip, you must disclose any
                        relevant medical conditions, special needs or
                        requirements, including but not limited to, mobility
                        aids, allergies, oxygen equipment or other equipment.
                        Please note that we may not be able to accommodate
                        certain requests without prior notice and approval.
                      </div>
                      <div>
                        c. You are expected to behave in a respectful and safe
                        manner during the trip. We reserve the right to refuse
                        Services or remove any Passenger whose behavior is
                        disruptive, harmful, or poses a risk to others, without
                        any refund or compensation to you.
                      </div>
                      <div>
                        d. If the Passenger is a minor, a legal guardian must
                        complete and sign the Consent Form prior to the
                        scheduled trip.
                      </div>
                      <div>
                        e. Smoking, eating, and drinking are prohibited in the
                        vehicle unless there is a disclosed medical condition
                        that requires it.
                      </div>
                      <div>
                        f. The Passenger must comply with all local, state, and
                        federal laws during the trip.
                      </div>
                      <div>
                        g. The Passenger is responsible for ensuring the
                        belongings are secured during the trip. We are not
                        responsible for lost or damaged items.
                      </div>
                      <div>
                        h. You agree to be bound by the refund and cancellation
                        policies outlined in these Terms and Conditions.
                      </div>
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Limitation of Liability and Liability Waiver.</u>
                      </strong>{" "}
                      To the fullest extent permitted by applicable law and
                      regulation, in no event shall Connect Care Trans or any of
                      its members, managers, officers, employees, agents,
                      drivers, or contractors be liable to you or any third
                      party for any direct, indirect, consequential, incidental,
                      or punitive damages relating to the provision of the
                      Services, regardless of whether such damages are
                      foreseeable or whether Connect Care Trans has been advised
                      of the possibility of such damages, except where such
                      damages or injuries are caused by the gross negligence or
                      willful misconduct of Connect Care Trans. Further, Connect
                      Care Trans shall not be responsible for delays or
                      interruptions in Services due to traffic, weather, road
                      conditions, or other circumstances beyond its control. You
                      acknowledge and agree that by using the Services, you, and
                      your guardians, agree to waive, release, and hold harmless
                      Connect Care Trans from any claims demands, or liabilities
                      arising from any injuries, damages, or losses incurred
                      during the trip, to the fullest extent permitted by
                      applicable law, except those resulting from the gross
                      negligence or willful misconduct of Connect Care Trans.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>Indemnification.</u>
                      </strong>{" "}
                      You shall indemnify, defend, and hold harmless Connect
                      Care Trans and its members, managers, employees, agents,
                      or representatives (<strong>“Indemnified Party”</strong>)
                      against any and all losses, damages, liabilities,
                      deficiencies, claims, actions, judgments, settlements,
                      interest, penalties, fines, costs, or expenses of whatever
                      kind, including reasonable attorneys’ fees, that are
                      incurred by Indemnified Party (collectively,{" "}
                      <strong>“Losses”</strong>), arising out of or related to
                      any claim alleging (1) breach of these Terms and
                      Conditions by you, (2) any bodily injury, death of any
                      person or damage to real and tangible property caused by
                      the negligent action or omission of you, and (3) failure
                      by you to comply with any applicable law or regulation.
                      This indemnification obligation shall survive the
                      termination of Services.
                    </div>
                    <br />
                    <div>
                      <strong>
                        <u>General Provisions.</u>
                      </strong>
                    </div>
                    <br />
                    <div style={{ marginLeft: "40px", marginRight: "40px" }}>
                      <div>
                        (a) <u>Entire Agreement.</u> The Terms and Conditions,
                        including and together with any related exhibits,
                        schedules, and attachments constitute the sole and
                        entire agreement of the parties with respect to the
                        subject matter contained herein, and supersedes all
                        prior and contemporaneous understandings, agreements,
                        representations, and warranties, both written and oral,
                        regarding such subject matter.
                      </div>{" "}
                      <br />
                      <div>
                        (b) <u>Assignment.</u> While Passenger may not assign
                        the right and obligation under these Terms and
                        Conditions to any third party, Connect Care Trans may
                        assign its rights to a third party, provided that such
                        assignment shall not affect the provision of the agreed
                        upon Services and other obligations under these Terms
                        and Conditions.
                      </div>{" "}
                      <br />
                      <div>
                        (c) <u>No Waiver.</u> No waiver by any party of any of
                        the provisions of these Terms and Conditions shall be
                        effective unless explicitly set forth in writing and
                        signed by the party so waiving. No failure to exercise
                        any right or remedy arising from these Terms and
                        Conditions shall be construed as a waiver of any other
                        right and remedy.
                      </div>{" "}
                      <br />
                      <div>
                        (d) <u>Relationship of the Parties.</u> Nothing
                        contained in these Terms and Conditions shall be
                        construed as creating any agency, partnership, joint
                        venture, or other form of joint enterprise, employment,
                        or fiduciary relationship between the parties, and
                        neither party shall have the authority to contract for
                        or bind the other party in any manner whatsoever.
                      </div>{" "}
                      <br />
                      <div>
                        (e) <u>Survival.</u> The provisions of these Terms and
                        Conditions shall survive the termination of these Terms
                        and Conditions to the extent necessary to effectuate the
                        terms contained herein.
                      </div>{" "}
                      <br />
                      <div>
                        (f) <u>Dispute Resolution.</u> In the event of any
                        controversy or claim arising out of or relating to the
                        Terms and Conditions, the parties hereto shall consult
                        and negotiate with each other in good faith and attempt
                        to reach a solution satisfactory to both parties. If the
                        parties cannot agree to a resolution within 30 days
                        after the aggrieved party’s written notice to the other
                        party, the terms of this provision shall be deemed
                        fulfilled.
                      </div>{" "}
                      <br />
                      <div>
                        (g) <u>Governing Law; Venue; Waiver of Jury Trial.</u>{" "}
                        The Terms and Conditions shall be governed by and
                        construed in accordance with the laws of the State of
                        Illinois (without giving effect to its laws of
                        conflicts). The parties agree that any legal action or
                        proceeding with respect to any of their respective
                        obligations under these Terms and Conditions shall be
                        brought exclusively in the County of Cook, State of
                        Illinois. THE PARTIES HERETO HEREBY IRREVOCABLY WAIVE
                        ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL
                        PROCEEDING ARISING OUT OF OR RELATED TO THE TERMS AND
                        CONDITIONS.
                      </div>{" "}
                      <br />
                      <div>
                        (h) <u>Severability.</u> If any provision of these Terms
                        and Conditions is held invalid or unenforceable by any
                        court of competent jurisdiction, the other provisions
                        will remain in full force and effect.
                      </div>
                      <br />
                    </div>
                    <h5>AGREED AND ACCEPTED BY:</h5>
                    <br />
                    <div>
                      {fields.map((field, index) => (
                        <div
                          key={index}
                          className={`input-field input-field-${index}`}
                        >
                          <strong>{field}:</strong>
                          <br />
                          <input
                            type="text"
                            style={{
                              borderWidth: "0 0 2px",
                              borderColor:
                                index === currentStep ? "red" : "black",
                              background: "white",
                            }}
                            placeholder={
                              field === "By"
                                ? "Write Relation, (self for )"
                                : ""
                            }
                            value={values[field]}
                            onChange={(e) =>
                              handleInputChange(field, e.target.value)
                            }
                            disabled={index === 0}
                          />
                          <br />
                          <br />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <button
            style={{
              position: "fixed",
              right: "20px",
              top: "20%",
              // transform: "translateY(-50%)", // Center vertically
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: 1050, // Ensures it stays above other elements
            }}
            onClick={nextStep}
          >
            {start === false
              ? "Start Signing"
              : Object.values(values).every((value) => value !== "")
              ? "Finish"
              : "Next"}
          </button>
          <button
            style={{
              position: "fixed",
              right: "20px",
              top: "40%",
              // transform: "translateY(-50%)", // Center vertically
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              zIndex: 1050, // Ensures it stays above other elements
            }}
            // onClick={}
          >
            Save
          </button>
          <PDFDownloadLink
            document={<SignPdf fields={fields} values={values} />}
            fileName={`Terms and Conditions`}
            className="text-dark"
          >
            PDF
          </PDFDownloadLink>
          <SignPdf2 />
          <button
            onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}
          >
            Download PDF
          </button> */}
          </div>
          <br />
        </>
      )}
      {!loading && checkOutView && isPaid && (
        <>
          {" "}
          <div className="container">
            <div className="row contact-area justify-content-center">
              <div className="col-lg-8 col-md-8 col-sm-8 order-1 order-lg-1">
                <div className="pricing-6-item">
                  <div className="section-title-6 text-center">
                    <div class="tab-content" id="myTabContent">
                      {/* <img
                      src={transaction}
                      alt="payment complete"
                      style={{ width: "40%" }}
                    /> */}

                      {/* <h5 className="">
                      Thank you! Your payment has been successfully processed. A
                      confirmation has been sent to your email.
                    </h5> */}
                      <div
                        id="successMessage"
                        class="alert alert-success text-center mt-3"
                        // style={{ display: "none" }}
                      >
                        Thank you! Your payment has been successfully processed.
                        A confirmation has been sent to your email.
                      </div>
                    </div>
                    {/* <div><strong>Who we are</strong></div> */}
                    {/* <div>
              <strong>Link has either expired or removed.</strong>
            </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
      {!loading && checkOutView && !isPaid && (
        <>
          <div className="container">
            <div className="row contact-area justify-content-center">
              <div className="col-lg-7 col-md-8">
                <div className="row contact-area justify-content-center">
                  <ul
                    class="nav nav-tabs gap-3 justify-content-center"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        // href="terms-and-conditions.html"
                        class="nav-link"
                        type="button"
                      >
                        Terms & Conditions
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" type="button">
                        Payment
                      </button>
                    </li>
                  </ul>
                  <div className="pricing-6-item" style={{ marginTop: "20px" }}>
                    <div className="section-title-6 text-center">
                      {/* <img
                      src={creditCard}
                      alt="Credit Card"
                      style={{ width: "40%" }}
                    /> */}
                      {/* <h3 className="title">Add your Card Details</h3> */}
                      {/* <h5>
                      Enter your card details to proceed with payment securely.
                    </h5> */}
                      {/* <h5 className="">{values["Passenger Name"]}</h5> */}
                      {/* <p></p> */}
                    </div>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="payment"
                        role="tabpanel"
                        aria-labelledby="payment-tab"
                      >
                        <form action="#" class="row" id="paymentForm">
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="fname" class="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                class="form-control rounded-0"
                                id="fname"
                                placeholder="Enter First Name"
                                value={customer.firstName}
                                readOnly
                                disabled
                                // onChange={handleChange("lastName")}
                                // required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="lname" class="form-label">
                                Last Name
                              </label>
                              <input
                                type="text"
                                class="form-control rounded-0"
                                id="lname"
                                placeholder="Enter Last Name"
                                value={customer.lastName}
                                readOnly
                                disabled
                                // onChange={handleChange("lastName")}
                                // required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="Email" class="form-label">
                                Email
                              </label>
                              <input
                                type="email"
                                class="form-control rounded-0"
                                id="Email"
                                placeholder="Enter Email Address"
                                value={customer.email}
                                readOnly
                                disabled
                                // onChange={handleChange("email")}
                                // required
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-3">
                              <label for="Phone" class="form-label">
                                Phone
                              </label>
                              <input
                                type="tel"
                                class="form-control rounded-0"
                                id="Phone"
                                placeholder="Enter Phone Number"
                                value={customer.phone}
                                readOnly
                                disabled
                                // onChange={handlePhone("phone")}
                                // required
                              />
                            </div>
                          </div>
                          <div class="col-md-12 mb-3">
                            <h5 style={{ color: "#22a3b3" }}>Card Detail</h5>
                          </div>
                          {/* <hr /> */}
                          {/* <div class="col-md-12">
                          <div class="mb-3">
                            <label for="name" class="form-label">
                              Name on Card
                            </label>
                            <input
                              type="text"
                              class="form-control rounded-0"
                              id="name"
                              placeholder="Enter Name on Card"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for="card" class="form-label">
                              Card Number
                            </label>
                            <input
                              type="text"
                              class="form-control rounded-0"
                              id="card"
                              placeholder="Enter Your Card Number"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="expiry" class="form-label">
                              Expiry Date
                            </label>
                            <input
                              type="text"
                              class="form-control rounded-0"
                              id="expiry"
                              placeholder="Enter Expiry Date"
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label for="cvv" class="form-label">
                              CVV
                            </label>
                            <input
                              type="text"
                              class="form-control rounded-0"
                              id="cvv"
                              placeholder="Enter CVV"
                              required
                            />
                          </div>
                        </div> */}

                          {/* <div class="d-grid">
                          <button type="submit" class="btn btn-primary">
                            Pay
                          </button>
                        </div> */}
                        </form>
                      </div>
                    </div>
                    <div className="">
                      {/* <div className="stripe-container"> */}
                      <PaymentForm
                        // applicationId="sandbox-sq0idb-P5J4jehUPFVVqe7yWrkW3w"
                        applicationId={SQUARE_UP_APPLICATION_ID}
                        cardTokenizeResponseReceived={async (
                          token,
                          verifiedBuyer
                        ) => {
                          const response = await fetch(
                            `${SERVERADDRESS}/squareup/create-payment`,
                            {
                              method: "POST",
                              headers: {
                                "Content-type": "application/json",
                                // Authorization: `Bearer ${SQUARE_UP_OAUTH_ACCESS_TOKEN}`,
                              },
                              body: JSON.stringify({
                                sourceId: token.token,
                                referenceId: refId,
                                link: fullUrl,
                              }),
                            }
                          );
                          if (response.ok) {
                            // This checks if the HTTP status is in the range 200–299
                            const data = await response.json(); // Parse the JSON response
                            if (data.success) {
                              setIsPaid(true);
                              // navigate("/");
                            }
                            // console.log(data, data.success, "ssssssssssss"); // Access the 'ok' property from the response
                          }
                          // console.log("responseresponseresponseresponse", response);
                          // const response = await axios.post(
                          //   `${SERVERADDRESS}
                          //   /squareup/create-payment`,
                          //   {
                          //     sourceId: token.token, // No need to stringify
                          //   },
                          //   {
                          //     headers: {
                          //       "Content-Type": "application/json", // Correct header for JSON
                          //     },
                          //   }
                          // );

                          console.log("token:", token);
                          console.log("verifiedBuyer:", verifiedBuyer);
                        }}
                        locationId="LAS3VXQZVFGW2"
                      >
                        <CreditCard
                          buttonProps={{
                            css: {
                              backgroundColor: "#001c3d",
                              fontSize: "16px",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#001c3d",
                              },
                            },
                          }}
                        />
                      </PaymentForm>
                      {/* </div> */}
                      {/* <div><strong>Who we are</strong></div> */}
                      {/* <div>
              <strong>Link has either expired or removed.</strong>
            </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </>
      )}
      <Footer />
    </>
  );
};

export default SignTermsAndConditions;
